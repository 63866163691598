<template>
  <div class="bookProperty" :class="{ relative: !sticky }">
    <div :class="{ 'container-fluid plr-80': sticky }">
      <div class="row">
        <div class="offset-md-6 col-md-6">
          <div class="d-flex justify-content-end">
            <div class="px-3 border-right mr-5">{{ totalRooms }} room(s)</div>
            <div class="px-4 ml-5">
              <h3>{{ cart[0].currencyCode }} {{ totalAmount }}</h3>
              <p>Total price<span>(VAT included)</span></p>
            </div>
            <div class="text-right border-left pl-4">
              <button @click="$emit('proceed')" class="btn btn-main px-4">
                {{ buttonText }}</button
              ><br />
              <button v-if="canClear" @click="$emit('clear')" class="btn px-0 text-underline small">
                <u>Clear selection</u>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookingCheckout",
  props: {
    buttonText: {
      type: String,
      default: "Book Now",
    },
    cart: {
      type: Array,
      required: true,
    },
    canClear: {
      type: Boolean,
      default: true,
    },
    sticky: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    totalAmount() {
      return this.cart.reduce((accumulator, item) => {
        return accumulator + item.price * item.quantity;
      }, 0).toFixed(2);
    },
    totalRooms() {
      return this.cart.reduce((accumulator, item) => {
        return accumulator + item.guests;
      }, 0);
    },
  },
};
</script>
